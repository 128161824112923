<template>
  <b-card no-body class="mb-5">
    <b-row no-gutters align-v="stretch">
      <!-- Image column -->
      <b-col
        class="d-none d-md-block"
        cols="12"
        lg="5"
        xxl="5"
        style="background-color: #FAFAFA; max-height: 450px"
      >
        <img
          :src="imageSrc"
          style="width: 100%; height: 100%; object-fit: cover"
          alt=""
        />
      </b-col>
      <b-col
        cols="12"
        lg="7"
        xxl="7"
        class="p-4 default-bg"
        align-self="stretch"
      >
        <b-row align-v="stretch" style="min-height: 100%">
          <b-col cols="12">
            <h2
              class="mb-0 text-default med-font"
              style="font-size: 22px; line-height: 32px"
            >
              {{ item.medicinename }}
            </h2>
             <p class="mb-0">
              {{ item.dosage }} | {{ item.unit }} | {{ duration }}
            </p>
          </b-col>
          <b-col cols="12">
            <div class="d-block d-md-none pt-6"></div>
            <b-row class="mt-2 mb-2" v-if="item.symptom">
              <b-col cols="12">
                  <b-list-group flush>
                    <b-list-group-item
                     class="d-flex text-default justify-content-between align-items-center"
                      @click="
                        $emit(
                          'show-modal',
                          'What this is good for',
                          formatGoodFor(item.symptom)
                        )
                      "
                      style="cursor: pointer"
                    >
                      What this is good for <span class="next-arrow-cyan"></span>
                    </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row> 

            <b-row class="mt-2 mb-2" v-if="item.whattoexpect.text">
              <b-col cols="12">
                  <b-list-group flush>
                 <b-list-group-item
                    class="d-flex text-default justify-content-between align-items-center"
                    @click="
                      $emit(
                        'show-modal',
                        'What to expect',
                        item.whattoexpect.html
                      )
                    "
                    style="cursor: pointer"
                  >
                    What to expect <span class="next-arrow-cyan"></span>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row> 
            <b-row class="mt-2 mb-2" v-if="item.sideeffects.text">
              <b-col cols="12">
                <b-list-group flush>
                  <b-list-group-item
                    class="d-flex text-default justify-content-between align-items-center"
                    @click="
                      $emit(
                        'show-modal',
                        'What are the side effects',
                        item.sideeffects.html
                      )
                    "
                    style="cursor: pointer"
                  >
                    What are the side effects
                    <span class="next-arrow-cyan"></span>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
            <div class="d-none d-md-block text-right mt-4">
              <!-- Button to show description -->
              <b-button
                pill
                size="lg"
                variant="info"
                style="min-width: 220px"
                :to="'/subscription/' + item.id"
              >
                View subscription
              </b-button>
            </div>
            <div class="d-block d-md-none text-right mt-4">
              <!-- Button to show description -->
              <b-button
                block
                pill
                variant="info"
                :to="'/subscription/' + item.id"
              >
                View subscription
              </b-button>
            </div>
            <div class="d-block d-md-none pb-3"></div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        class="d-block d-md-none"
        cols="12"
        lg="5"
        xxl="5"
        style="background-color: #FAFAFA; max-height: 450px"
      >
        <img
          :src="imageSrc"
          style="width: 100%; height: 100%; object-fit: cover"
          alt=""
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    imageSrc() {
      return this.item.image !== null
        ? this.item.image
        : require("@/assets/images/dashboard/articlebg.png");
    },
  },
  data() {
    return {
      duration: ""
    }
  },
  methods: {
    formatGoodFor(content) {
      let result = content.map((item) => {
        return item.name;
      });
      return result.join(", ");
    },
  },
  async mounted() {
    console.log(this.item)
    switch (this.item.subscriptiontype) {
      case "Monthly": 
        this.duration = "Monthly Supply"
        break;
      case "Quarterly":  
        this.duration = "3 Months Supply"
        break;
    }
  },
};
</script>

<style lang="scss" scoped>
.list-group-item {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-color: $primary;
  text-transform: uppercase;
  border-bottom-color: #b0baab;
  padding-bottom: 0.5rem;
  padding-top: 1.2rem;
}
</style>
